import React, { Component } from 'react';
import appConfig from '../shared/appConfig/appConfig';
import { handleGetReq } from '../shared/services/AppendHeadersService';
import MUIDataTable from "mui-datatables";
import { Row, Col } from 'react-bootstrap';
import toasterService from '../shared/services/ToasterService';
import TripDetails from './TripDetails';
import languageConstants from '../shared/appConfig/languageConstants';

/* Redux Import Start */
import { connect } from 'react-redux';
import { setShowLoader } from '../shared/redux/actions/common.actions'
import { setTripDetails } from '../shared/redux/actions/dashboard.actions'
import DashboardMetrics from '../shared/modules/DashboardMetrics';
import DashboardFilter from '../Components/DashboardFilter';

/* Redux Import End */

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            count: 0,
            page: 0,
            rowsPerPage: 10,
            deliveryStatus: this.props.appSetting.PartnerLocationSettings?.CustomerDelScheduleSmsResponse ? '0,1,2' : '1,2',
            filterStatus: this.props.appSetting.PartnerLocationSettings?.CustomerDelScheduleSmsResponse ? ['Awaiting Customer Confirmation', 'Scheduled', 'In-Progress'] : ['Scheduled', 'In-Progress'],
            sortOrder: 'desc',
            sortBy: this.props.appSetting.PartnerLocationSettings.TrackDeliveryListStaticFields.filter((data) => data.defaultSort === true)[0].name,
            searchCriteria: '',
            showModal: false,
            filterStatusString: this.props.appSetting.PartnerLocationSettings?.CustomerDelScheduleSmsResponse ? ['Awaiting Customer Confirmation', 'Scheduled', 'In-Progress', 'Completed', 'Canceled'] : ['Scheduled', 'In-Progress', 'Completed', 'Canceled'],
            columns: [],
            columnSortDirection: [],
            columnCount: this.props.appSetting.PartnerLocationSettings.TrackDeliveryListStaticFields.length,
            nameColumnSortDirection: null,
        }
        this.tableRef = React.createRef();
    }

    /**
     * setFilterOptions()
     * This function is used to set the filter options.
     */
    setFilterOptions = (name) => {
        return ({
            render: (v) => {
                return `${name}: ${v}`;
            }
        })
    }

    /**
     * updateColumnData()
     * This function is used to update the column data
     */
    updateColumnData = async(sortDirections) => {
        try {
            const { appSetting, setShowLoader } = this.props;
            setShowLoader(true);
            appSetting.PartnerLocationSettings.TrackDeliveryListStaticFields.map((item, index) => {
                if(item.options.sort){
                    appSetting.PartnerLocationSettings.TrackDeliveryListStaticFields[index].options["sortOrder"] = sortDirections[index]
                    appSetting.PartnerLocationSettings.TrackDeliveryListStaticFields[index].options["customFilterListOptions"] = this.setFilterOptions(item.name)
                    appSetting.PartnerLocationSettings.TrackDeliveryListStaticFields[index].options["sort"] = true
                } else {
                    appSetting.PartnerLocationSettings.TrackDeliveryListStaticFields[index].options["sort"] = false
                }
                return true
            })
            let tmp = appSetting.PartnerLocationSettings.TrackDeliveryListStaticFields;
            const valueToChange = tmp.filter(item => item.fieldtype === 2)
            const valueToChangeIndex = tmp.findIndex((item) => item.fieldtype === 2);
            //Assign value from the db to ReceiptNumber
            if(valueToChange.length === 1){ 
                // eslint-disable-next-line array-callback-return
                valueToChange.map((key1, index1) => {
                    // eslint-disable-next-line array-callback-return
                    appSetting.PartnerLocationSettings.StaticFields.map((key2, index2) => {
                        if(key1.MappingDBFieldName === key2.MappingDBFieldName) {
                            tmp[valueToChangeIndex].label = key2.DisplayName
                        }
                    });
                })
                
            }
            this.setState({
                columns: tmp
                // columns: appSetting.PartnerLocationSettings.TrackDeliveryListStaticFields
            })
            setShowLoader(false)
        } catch (err) {
            console.log(err)
        }
    }

    /**
     * componentWillMount()
     * This function Executes just before rendering takes place.
     */
    componentWillMount = async () => {
        try {
            const { appSetting } = this.props
            let newColumnSortDirection = []
            for(let i=1; i<=this.state.columnCount; i++){
                newColumnSortDirection.push("null")
            }
            await this.setState({
                columnSortDirection: newColumnSortDirection
            })
            this.updateColumnData(newColumnSortDirection)
            await this.setState({
                supportPhoneNumber: appSetting.BungiiSettings.SupportPhoneNumber
            });
        } catch (e) { console.log(e) }
    }

    /**
     * componentDidMount()
     * This function Executes only after the first render.
     */
    componentDidMount = async () => {
        try {
            await this.getData();
        }
        catch (err) {
            console.log(err)
        }

    }

    /**
     * getData()
     * This function is used to get the data
     */
    getData = async (isSort = false) => {
        try {
            const { accessToken, setShowLoader } = this.props
            const { PartnerLocationSettings } = this.props.appSetting
            setShowLoader(true)
            let data = {
                DeliveryStatus: this.state.deliveryStatus,
                SortBy: this.state.sortBy,
                SortOrder: this.state.sortOrder,
                PageNo: this.state.page,
                PageSize: this.state.rowsPerPage,
                SearchCriteria: this.state.searchCriteria,

                // TODO : rename key to EapiTrackDeliveryEnabled in BE
                IsEapiPortal: PartnerLocationSettings.EapiTrackDeliveryEnabled ? 1 : 0
            }
            
            let response = await handleGetReq(appConfig.urls.deliveryList, data, accessToken);
            
            if (response !== undefined && response.data.Error === null) {
                this.setState({
                    data: response.data.PartnerDeliveries,
                    count: response.data.TotalRowCount
                })
                if(!isSort) {
                    this.tableRef.current.changePage(0); /*reset the page to 0 on click of 'apply', 'reset', 'search', 'sort'*/
                }
            }
            else {
                toasterService.showCustomToasty(response.data.Error.Message, "error");
            }
            setShowLoader(false)
        }
        catch (err) {
            console.log(err);
        }
        
    }

    /**
     * changePage()
     * This function is triggered on changing the page number.
     */
    changePage = async (page, rowsPerPage) => {
        try {
            const { accessToken, setShowLoader } = this.props
            let data = {
                DeliveryStatus: this.state.deliveryStatus,
                SortBy: this.state.sortBy,
                SortOrder: this.state.sortOrder,
                PageNo: page,
                PageSize: this.state.rowsPerPage,
                SearchCriteria: this.state.searchCriteria
            }
            setShowLoader(true)
            let response = await handleGetReq(appConfig.urls.deliveryList, data, accessToken);
            if (response !== undefined && response.data.Error === null) {
                await this.setState({
                    data: response.data.PartnerDeliveries,
                    page: page
                })
            }
            else {
                toasterService.showCustomToasty(response.data.Error.Message, "error");
            }
            setShowLoader(false)
        }
        catch (err) {

        }
    }

    /**
     * changeRowsPerPage()
     * This function is triggered on changing rows per page
     */
    changeRowsPerPage = async (page,rowsPerPage) => {
        this.setState({
            rowsPerPage: rowsPerPage
        })
        try {
            const { accessToken, setShowLoader } = this.props
            let data = {
                DeliveryStatus: this.state.deliveryStatus,
                SortBy: this.state.sortBy,
                SortOrder: this.state.sortOrder,
                PageNo: page,
                PageSize: rowsPerPage,
                SearchCriteria: this.state.searchCriteria
            }
            setShowLoader(true)
            let response = await handleGetReq(appConfig.urls.deliveryList, data, accessToken);
            if (response !== undefined && response.data.Error === null) {
                this.setState({
                    data: response.data.PartnerDeliveries,
                })
            }
            else {
                toasterService.showCustomToasty(response.data.Error.Message, "error");
            }
            setShowLoader(false)
        }
        catch (err) {

        }
    }

    /**
     * applyFilters()
     * This function is used when clicked on apply filters
     */
    applyFilters = async (event) => {
        try {
            event.preventDefault();
            let checkboxes = document.getElementsByName('deliveryStatus');
            let checkboxesChecked = [];
            let filterSelected = [];
            for (let i = 0; i < checkboxes.length; i++) {
                if (checkboxes[i].checked) {
                    filterSelected.push(checkboxes[i].value);
                    checkboxesChecked.push(checkboxes[i].id);
                }
            }
            await this.setState({
                filterStatus: filterSelected,
                deliveryStatus: checkboxesChecked.length > 0 ? checkboxesChecked.toString() : 0,
            })
            await this.getData();
        }
        catch (err) {
            console.log(err);
        }
    }

    /**
     * resetFilters()
     * This function is used when clicked on reset filters
     */
    resetFilters = async () => {
        try {
            await this.setState({
                deliveryStatus: this.props.appSetting.PartnerLocationSettings.CustomerDelScheduleSmsResponse ? '0,1,2' : '1,2',
                filterStatus: this.props.appSetting.PartnerLocationSettings.CustomerDelScheduleSmsResponse ? ['Awaiting Customer Confirmation', 'Scheduled', 'In-Progress'] : ['Scheduled', 'In-Progress'],
            })
            let checkboxes = document.getElementsByName('deliveryStatus');
            for (let i = 0; i < checkboxes.length; i++) {
                if (checkboxes[i].value === this.state.filterStatus[i]) {
                    checkboxes[i].checked = true;
                }
                else {
                    checkboxes[i].checked = false;
                }
            }
            document.getElementById('checkUncheckAll').checked = false;
            await this.getData();
        }
        catch (err) {
            console.log(err);
        }
    }

    /**
     * sortChange()
     * This function is used to change the sort direction
     */
    sortChange = (column, order) => {
        this.setState({
            nameColumnSortDirection: order
        });
    };

     /**
     * sort()
     * This function is triggered on click of table head to sort.
     */
    sort = async (column, order) => {
        try {
            const { appSetting, setShowLoader } = this.props;
            var cases = [];
            let isSort = true;
            setShowLoader(true);
            /*Build each case dynamically*/
            appSetting.PartnerLocationSettings.TrackDeliveryListStaticFields.map((item, index) => {
                cases.push(item.name)
                return true
            })
            let sortDirections = [];
            for(let i=1; i<=this.state.columnCount; i++){
                sortDirections.push("null")
            }
            for(let i=1; i<=this.state.columnCount; i++){
                switch (column) {
                    case cases[i]:
                        sortDirections[i] = order;
                        break;
                    default:
                        break;
                }
            }
            
            await this.setState({
                sortOrder: order,
                columnSortDirection: sortDirections,
                sortBy: column
            })
            this.updateColumnData(sortDirections);
            setShowLoader(false)
            await this.getData(isSort);
        }
        catch (err) {
            console.log(err);
        }
    }

    /**
     * keyPressed()
     * This function is triggered on click of any key pressed.
     */
    keyPressed = async (event) => {
        try {
            document.getElementById('closeIcon').classList.add('closeIcon');
            var code = event.keyCode || event.which;
            if (code === 13) {
                await this.setState({
                    searchCriteria: event.target.value
                })
                await this.getData();
            }
        }
        catch (err) {
            console.log(err);
        }

    }

    /**
     * clearSearch()
     * This function is triggered on click of close icon of search box.
     */
    clearSearch = async () => {
        try {
            let searchCriteria = document.getElementById('searchText');
            searchCriteria.value = ''
            await this.setState({
                searchCriteria: ''
            })
            document.getElementById('closeIcon').classList.remove('closeIcon');
            await this.getData();
        }
        catch (err) {
            console.log(err);
        }

    }

    /**
     * handleCloseModal()
     * This function is triggered on click of close modal.
     */
    handleCloseModal = async () => {
        try {
            this.setState({
                showModal: false
            })
        }
        catch (err) {
            console.log(err);
        }
    }

    /**
     * onRowClick()
     * This function is triggered on click of each row.
     */
    onRowClick = async (rowData) => {
        try {
            await this.setState({
                pickuprequestid: rowData[0],
            })
            await this.getPartnerDetails(rowData[0]);
        }
        catch (err) {
            console.log(err);
        }

    }

    /**
     * getPartnerDetails()
     * This function is used to get the partner details.
     */
    getPartnerDetails = async (pickuprequestid) => {
        try {
            const { accessToken, setTripDetails, setShowLoader } = this.props
            setShowLoader(true)
            let tripDetails = await handleGetReq(appConfig.urls.tripDetails, { pickuprequestid: pickuprequestid }, accessToken);
            if (tripDetails !== undefined && tripDetails.data.Error === null) {
                /**
                 * Set redux states
                 */
                await setTripDetails(tripDetails.data.Details);
                await this.setState({
                    showModal: true
                })
                setShowLoader(false)
            } else if(tripDetails.data.Error.Code === 20009){
                setShowLoader(false)
                toasterService.showCustomToasty(languageConstants.errors.invalidUser.message, "error");
                setTimeout(function(){ 
                    window.location.replace(`/`);
                }, 2000);
            }
            else {
                toasterService.showCustomToasty(tripDetails.data.Error.Message, "error");
            }
        } catch (e) { console.log(e) }
    }

    render() {
        const data = this.state.data;
        let filterStatusString = this.state.filterStatus;
        const columns = this.state.columns;
        const options = {
            filter: false,
            filterType: 'checkbox',
            responsive: 'standard',
            print: false,
            selectableRows: "none",
            download: false,
            serverSide: true,
            viewColumns: false,
            count: this.state.count,
            page: this.state.page,
            rowsPerPageOptions: [10, 20, 30],
            customToolbar: ()=> <DashboardFilter
                    applyFilters= {this.applyFilters}
                    onSearch={this.keyPressed}
                    clearSearch={this.clearSearch}
                    filterStatusString={filterStatusString}
                    resetFilters={ this.resetFilters}
                />,
            search: false,
            searchText: this.state.searchCriteria,
            textLabels: {
                body: {
                    noMatch: 'Sorry, no records found',
                },
            },
            onColumnSortChange: async (changedColumn, direction) => {
                let order = "desc";
                if (direction === "asc") {
                    order = "asc";
                }
        
                this.sort(changedColumn, order);
                this.sortChange(changedColumn, order)
            },
            onRowClick: this.onRowClick,
            onTableChange: (action, tableState) => {
                switch (action) {
                    case 'changePage':
                        this.changePage(tableState.page, tableState.rowsPerPage);
                        break;
                    case 'changeRowsPerPage':
                        this.changeRowsPerPage(tableState.page, tableState.rowsPerPage);
                        break;
                    case 'resetFilters':
                        this.resetFilters();
                        break;
                    default:
                        return 'No action.';
                }
            }
        };
        return (
            <React.Fragment>
                <DashboardMetrics/>
                <Row className={`dataTable ${this.state.sortBy} ${this.state.sortOrder}`}>
                    <Col>
                        <MUIDataTable
                            title={"Delivery List"}
                            innerRef={this.tableRef}
                            data={data}
                            className={columns.filter((item) => item.options.display === true).length === 5 ? "five-cols" : ""}
                            columns={columns}
                            options={options}
                        />
                    </Col>
                </Row>
                {this.state.showModal &&
                    < TripDetails showModal={this.state.showModal} handleCloseModal={this.handleCloseModal} tripDetails={this.state.tripDetails} pickuprequestid={this.state.pickuprequestid} getData={this.getData} supportPhoneNumber={this.state.supportPhoneNumber}></TripDetails>
                }

            </React.Fragment>
        );
    }
}

const ConnectedDashboard = connect(
    (
        { 
            user: {
                accessToken
            }, 
            common: {
                showLoader, appSetting
            },
            dashboard: {
                tripDetails
            }
        }
    ) => 
    (
        {
            accessToken,
            tripDetails,
            showLoader,
            appSetting
        }
    ),
    dispatch => (
        {
            setTripDetails: tripDetails => dispatch(setTripDetails(tripDetails)),
            setShowLoader: showLoader => dispatch(setShowLoader(showLoader)),
        }
    )
)(Dashboard);

export default ConnectedDashboard;
export {ConnectedDashboard}